@media screen and (min-width: 320px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

body {
  margin: 7px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.poolLinkContainer {
  display: flex;
  flex-direction: row;
}

.raceLinkContainer {
  height: 40px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.sales {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}

.cell {
  font-weight: bold;
  width: 33.33%;
  text-align: center;
}

.chartContainer {
  margin-top: 20px;
  width: 100%;
  height: calc(100vh - 180px) !important;
}

.horseList{
  width: 25%;
  height: 100%;
  float: left;
}

.chart {
  width: 75%;
  height: 100% !important;
  float: right;
}

.lineChart  {
  margin: 1%;
}

.clock {
	display: flex;
	justify-content: center;
	align-content: center;
	min-height: 100vh;
  color: rgb(221, 35, 175);
  background-image: url(components/pictures/swedishTrot.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}


